import React from 'react';
import banner_image from "../images/about_image.jpg";
function About() {
    return (
        <div>
            <div className="page-title-area">
                <div className="d-table">
                    <div className="d-table-cell">
                        <div className="container">
                            <div className="page-title-content">
                                <h2>About Us</h2>
                                <ul>
                                    <li><a href="/">Home</a></li>
                                    <li>About Us</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="about-area section-padding">
                <div className="container">
                    <div className="row d-flex align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <h6 className="sub-title">About Our Company</h6>
                                <h2>We are Committed to Provide the Best Services to Grow Your Business</h2>
                                <p>Welcome to praneesh Infotech, where innovation meets expertise to redefine the digital landscape. Established with a vision to revolutionize the way businesses operate, we are a dynamic startup software company committed to delivering cutting-edge solutions tailored to meet the evolving needs of our clients. At the heart of our mission is a team of passionate professionals driven by a shared commitment to excellence, creativity, and technological advancement.</p>
                                <p>Our journey began with a simple yet powerful idea: to empower businesses through innovative software solutions that enhance efficiency, productivity, and overall performance. With a focus on staying ahead of the curve in a rapidly changing technological landscape, we continuously explore and embrace the latest trends to provide our clients with the most robust and scalable software applications.</p>
                                <p>What sets us apart is not just our technical prowess but also our dedication to understanding the unique challenges faced by each client. We take pride in our collaborative approach, working closely with our clients to develop solutions that not only meet but exceed their expectations. Our customer-centric philosophy is embedded in every aspect of our operations, ensuring a seamless experience from project inception to delivery.</p>
                                <p>As we embark on this exciting journey, we invite you to join us in shaping the future of technology. Whether you are a startup seeking a reliable technology partner or an established enterprise looking to optimize your operations, praneesh Infotech is here to guide you towards success. Let's build the future together!</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-image">
                                <img src={banner_image} alt="About" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default About
