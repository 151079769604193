import React from 'react'
import Logo from "../images/logo.png";
function footer() {
    return (
        <div>
            <section className="footer-subscribe-wrapper">
                <div className="footer-area ptb-100">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="single-footer-widget">
                                    <a className="footer-logo" href="/">
                                        <img src={Logo} className="white-logo" alt="logo" />
                                    </a>
                                    <p>Transform your ideas into reality with our custom software development services. We
                                        specialize in creating scalable, secure, and intuitive software applications tailored to
                                        meet the specific requirements of your business.</p>
                                    <ul className="footer-social">
                                        <li>
                                            <a href="https://www.facebook.com/profile.php?id=61554983072140"> <i className="fab fa-facebook-f"></i></a>
                                        </li>
                                        <li>
                                            <a href="https://wa.me/message/PETQGLMYVET3L1"> <i className="fab fa-whatsapp"></i></a>
                                        </li>
                                        <li>
                                            <a href="https://www.instagram.com/praneesh_infotech?igsh=dmw4M3hxZjEwbThz"> <i className="fab fa-instagram"></i></a>
                                        </li>
                                        <li>
                                            <a href="https://www.linkedin.com/in/praneesh-infotech-07b6822a2/"> <i className="fab fa-linkedin"></i></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-6 col-sm-6">
                                <div className="single-footer-widget">
                                    <div className="footer-heading">
                                        <h3>Our Services</h3>
                                    </div>
                                    <ul className="footer-quick-links">
                                        <li><a href="service-android">Android App Development</a></li>
                                        <li><a href="service-ios">IOS App Development</a></li>
                                        <li><a href="service-website">Website Development</a></li>
                                        <li><a href="service-webapp">Web App Development</a></li>
                                        <li><a href="service-ecom">E-Commere Development</a></li>
                                        <li><a href="service-poster">Social Media Poster Design</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-6 col-sm-6">
                                <div className="single-footer-widget">
                                    <div className="footer-heading">
                                        <h3>Useful Links</h3>
                                    </div>
                                    <ul className="footer-quick-links">
                                        <li><a href="about">About Us</a></li>
                                        <li><a href="contact">Contact Us</a></li>
                                        <li><a href="/">Privacy Policy</a></li>
                                        <li><a href="/">Terms & Conditions</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="single-footer-widget">
                                    <div className="footer-heading">
                                        <h3>Contact Info</h3>
                                    </div>
                                    <div className="footer-info-contact">
                                        <i className="flaticon-phone-call"></i>
                                        <h3>Phone</h3>
                                        <span><a href="tel:+919487924282">+91 94879 24282</a></span>
                                    </div>
                                    <div className="footer-info-contact">
                                        <i className="flaticon-envelope"></i>
                                        <h3>Email</h3>
                                        <span><a href="mailto:demo@example.com">praneeshinfotech@gmail.com</a></span>
                                    </div>
                                    <div className="footer-info-contact">
                                        <i className="flaticon-placeholder"></i>
                                        <h3>Address</h3>
                                        <span>Kavindapadi - Erode - 638455</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="copyright-area">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-6">
                            <p><i className="far fa-copyright"></i> 2023 Praneesh Infotech - All Rights Reserved.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="go-top">
                <i className="fas fa-chevron-up"></i>
                <i className="fas fa-chevron-up"></i>
            </div>
        </div>
    )
}

export default footer;