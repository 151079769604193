import React from 'react';
import Logo from "../images/logo.png";
function header() {
  return (
    <div>
      {/* <div className="preloader">
        <div className="loader">
          <div className="shadow"></div>
          <div className="box"></div>
        </div>
      </div> */}
      <div className="navbar-area">
        <div className="praneesh-responsive-nav">
          <div className="container">
            <div className="praneesh-responsive-menu">
              <div className="logo">
                <a href="/">
                  <img src={Logo} className="white-logo" alt="logo" />
                  <img src={Logo} className="black-logo" alt="logo" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="praneesh-nav">
          <div className="container">
            <nav className="navbar navbar-expand-md navbar-light">
              <a className="navbar-brand" href="/">
                <img src={Logo} className="white-logo" alt="logo" />
                <img src={Logo} className="black-logo" alt="logo" />
              </a>
              <div className="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <a href="/" className="nav-link">Home</a>
                  </li>
                  <li className="nav-item">
                    <a href="about" className="nav-link">About</a>
                  </li>
                  <li className="nav-item">
                    <a href="service" className="nav-link">Services <i className="fas fa-chevron-down"></i></a>
                    <ul className="dropdown-menu">
                      <li className="nav-item">
                        <a href="service-android" className="nav-link">Android Application</a>
                      </li>
                      <li className="nav-item">
                        <a href="service-ios" className="nav-link">IOS Application</a>
                      </li>
                      <li className="nav-item">
                        <a href="service-website" className="nav-link">Website Development</a>
                      </li>
                      <li className="nav-item">
                        <a href="service-webapp" className="nav-link">Web App Development</a>
                      </li>
                      <li className="nav-item">
                        <a href="service-ecom" className="nav-link">E-Commere Development</a>
                      </li>
                      <li className="nav-item">
                        <a href="service-poster" className="nav-link">Social Media Poster Design</a>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item">
                    <a href="contact" className="nav-link">Contact</a>
                  </li>
                </ul>
                <div class="other-option">
                  <a className="default-btn" href="mailto:praneesh infotech@gmail.com">Get It Support</a>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
      <div className="go-top">
        <i className="fas fa-chevron-up"></i>
        <i className="fas fa-chevron-up"></i>
      </div>
    </div >
  )
}

export default header;