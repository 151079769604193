import React from 'react';
import banner_image from "../images/banner_image.png";
import choose1_image from "../images/homepage1.png";
import choose2_image from "../images/homepage2.png";
function Home() {
    return (
        <div>
            <div className="home-4 home-area">
                <div id="particles-js"></div>
                <div className="d-table">
                    <div className="d-table-cell">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-6 col-md-12">
                                    <div className="main-banner-content">
                                        <h1>IT Solutions & Business Services Company</h1>
                                        <p>Transform your ideas into reality with our custom software development services. We specialize in creating scalable, secure, and intuitive software applications tailored to meet the specific requirements of your business.</p>
                                        <div className="banner-btn">
                                            <a className="default-btn-one" href="service">Our Service <span></span></a>
                                            <a className="default-btn-two" href="contact">Contact Us <span></span></a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                    <div className="banner-image">
                                        <img src={banner_image} alt="banner" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="overview-section section-padding">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="overview-image">
                                <img src={choose1_image} alt="choose1" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="overview-content">
                                <h6 className="sub-title">Why Choose Us?</h6>
                                <h2>Your Vision, Our Tech</h2>
                                <p>In a rapidly evolving digital landscape, selecting the right IT partner is crucial for the success and growth of your business. At praneesh Infotech, we understand the unique challenges and opportunities presented by the ever-changing world of technology. Here are compelling reasons why choosing us as your IT solutions provider is a strategic decision that propels your business forward.</p>
                                <ul className="features-list">
                                    <li> <span>Expertise That Matters</span></li>
                                    <li> <span>Tailored Solutions for Your Business</span></li>
                                    <li> <span>Cutting-Edge Technology</span></li>
                                    <li> <span>Security First</span></li>
                                    <li> <span>Seamless Integration</span></li>
                                    <li> <span>Client-Centric Approach</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="overview-section pt-70 pb-100">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="overview-content">
                                <h6 className="sub-title">WHY TRUST US?</h6>
                                <h2>Streamline your business with automation</h2>
                                <p></p>
                                <ul className="features-list">
                                    <li> <span>Industry Experience</span></li>
                                    <li> <span>Expertise in Technologies</span></li>
                                    <li> <span>Data Security</span></li>
                                    <li> <span>Transparent Processes</span></li>
                                    <li> <span>Quality Assurance</span></li>
                                    <li> <span>Security Measures</span></li>
                                    <li> <span>Client-Centric Approach</span></li>
                                    <li> <span>Dedicated Support</span></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="overview-image-2">
                                <img src={choose2_image} alt="choose2" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="services-section section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="section-title">
                                <h1>Our Services</h1>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-services-item">
                                <div className="services-icon">
                                    <i className="flaticon-project-management"></i>
                                </div>
                                <h3>Android App Development</h3>
                                <p>Embark on a journey of digital innovation with our Android App Development services at praneesh Infotech. We specialize in creating dynamic, user-friendly, and feature-rich Android applications that propel businesses forward in the mobile-first era.</p>
                                <div className="services-btn">
                                    <a href="service-android" className="read-more"><i className="bi bi-arrow-right-short"></i> Read More</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-services-item">
                                <div className="services-icon">
                                    <i className="flaticon-development"></i>
                                </div>
                                <h3>IOS App Development</h3>
                                <p>Where innovation meets the Apple ecosystem. Our iOS App Development services are tailored to deliver seamless, sophisticated, and user-centric applications that resonate with the high standards set by Apple.</p>
                                <div className="services-btn">
                                    <a href="service-ios" className="read-more"><i className="bi bi-arrow-right-short"></i> Read More</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-services-item">
                                <div className="services-icon">
                                    <i className="flaticon-analytics"></i>
                                </div>
                                <h3>Website Development</h3>
                                <p>Your go-to destination for innovative and tailored website development solutions. We specialize in creating visually stunning, user-friendly, and performance-driven websites that captivate audiences and elevate brands in the digital realm.</p>
                                <div className="services-btn">
                                    <a href="service-website" className="read-more"><i className="bi bi-arrow-right-short"></i> Read More</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-services-item">
                                <div className="services-icon">
                                    <i className="flaticon-cpu-1"></i>
                                </div>
                                <h3>Web Application Development</h3>
                                <p>Where innovation meets functionality. Our Web Application Development services are designed to empower businesses with scalable, secure, and intuitive solutions that streamline processes and elevate user experiences.</p>
                                <div className="services-btn">
                                    <a href="service-webapp" className="read-more"><i className="bi bi-arrow-right-short"></i> Read More</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-services-item">
                                <div className="services-icon">
                                    <i className="flaticon-coding"></i>
                                </div>
                                <h3>E-Commere Development</h3>
                                <p>Your trusted partner for cutting-edge e-commerce solutions. Our E-commerce Development Services are tailored to empower businesses of all sizes, providing scalable, secure, and online platforms that drive sales and enhance customer experiences.</p>
                                <div className="services-btn">
                                    <a href="service-ecom" className="read-more"><i className="bi bi-arrow-right-short"></i> Read More</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-services-item">
                                <div className="services-icon">
                                    <i className="flaticon-mobile-app"></i>
                                </div>
                                <h3>Social Media Poster Design</h3>
                                <p>Captivate your audience and elevate your brand with our Social Media Poster Design Services. We specialize in creating visually stunning and engaging posters tailored to the unique personality of your brand.</p>
                                <div className="services-btn">
                                    <a href="service-poster" className="read-more"><i className="bi bi-arrow-right-short"></i> Read More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Home
