import React from 'react'

function Service() {
    return (
        <div>
            <div className="page-title-area item-bg2">
                <div className="d-table">
                    <div className="d-table-cell">
                        <div className="container">
                            <div className="page-title-content">
                                <h2>Services</h2>
                                <ul>
                                    <li><a href="/">Home</a></li>
                                    <li>Services</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="services-section section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="section-title">
                                <h1>Our Services</h1>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-services-item">
                                <div className="services-icon">
                                    <i className="flaticon-project-management"></i>
                                </div>
                                <h3>Android App Development</h3>
                                <p>Embark on a journey of digital innovation with our Android App Development services at praneesh Infotech. We specialize in creating dynamic, user-friendly, and feature-rich Android applications that propel businesses forward in the mobile-first era.</p>
                                <div className="services-btn">
                                    <a href="service-android" className="read-more"><i className="bi bi-arrow-right-short"></i> Read More</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-services-item">
                                <div className="services-icon">
                                    <i className="flaticon-development"></i>
                                </div>
                                <h3>IOS App Development</h3>
                                <p>Where innovation meets the Apple ecosystem. Our iOS App Development services are tailored to deliver seamless, sophisticated, and user-centric applications that resonate with the high standards set by Apple.</p>
                                <div className="services-btn">
                                    <a href="service-ios" className="read-more"><i className="bi bi-arrow-right-short"></i> Read More</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-services-item">
                                <div className="services-icon">
                                    <i className="flaticon-analytics"></i>
                                </div>
                                <h3>Website Development</h3>
                                <p>Your go-to destination for innovative and tailored website development solutions. We specialize in creating visually stunning, user-friendly, and performance-driven websites that captivate audiences and elevate brands in the digital realm.</p>
                                <div className="services-btn">
                                    <a href="service-website" className="read-more"><i className="bi bi-arrow-right-short"></i> Read More</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-services-item">
                                <div className="services-icon">
                                    <i className="flaticon-cpu-1"></i>
                                </div>
                                <h3>Web Application Development</h3>
                                <p>Where innovation meets functionality. Our Web Application Development services are designed to empower businesses with scalable, secure, and intuitive solutions that streamline processes and elevate user experiences.</p>
                                <div className="services-btn">
                                    <a href="service-webapp" className="read-more"><i className="bi bi-arrow-right-short"></i> Read More</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-services-item">
                                <div className="services-icon">
                                    <i className="flaticon-coding"></i>
                                </div>
                                <h3>E-Commere Development</h3>
                                <p>Your trusted partner for cutting-edge e-commerce solutions. Our E-commerce Development Services are tailored to empower businesses of all sizes, providing scalable, secure, and online platforms that drive sales and enhance customer experiences.</p>
                                <div className="services-btn">
                                    <a href="service-ecom" className="read-more"><i className="bi bi-arrow-right-short"></i> Read More</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-services-item">
                                <div className="services-icon">
                                    <i className="flaticon-mobile-app"></i>
                                </div>
                                <h3>Social Media Poster Design</h3>
                                <p>Captivate your audience and elevate your brand with our Social Media Poster Design Services. We specialize in creating visually stunning and engaging posters tailored to the unique personality of your brand.</p>
                                <div className="services-btn">
                                    <a href="service-poster" className="read-more"><i className="bi bi-arrow-right-short"></i> Read More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="hire-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2 col-md-12">
                            <div className="hire-content">
                                <h6 className="sub-title">Want to work with us?</h6>
                                <h2>Digitally Transform & Grow Your Business</h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud consectetur voluptatem accusantium doloremque adipiscing elit.</p>
                                <div className="hire-btn">
                                    <a className="default-btn" href="tel:+919487924282">Call Now<span></span></a>
                                    <a className="default-btn-one" href="contact">Contact Us<span></span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Service
