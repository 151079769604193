import React from 'react'
import service_image from "../images/service-website.jpg";
function Website() {
    return (
        <div>
            <div className="page-title-area">
                <div className="d-table">
                    <div className="d-table-cell">
                        <div className="container">
                            <div className="page-title-content">
                                <h2>Website Development</h2>
                                <ul>
                                    <li><a href="/">Home</a></li>
                                    <li>Website Development</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="services-details-area section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="services-details-content">
                                <div className="services-details-image">
                                    <img src={service_image} alt="service" />
                                </div>
                                <h3>Website Development Services</h3>
                                <p>Welcome to praneesh Infotech, your go-to destination for innovative and tailored website
                                    development solutions. We specialize in creating visually stunning, user-friendly, and
                                    performance-driven websites that captivate audiences and elevate brands in the digital realm.
                                </p>
                                <div className="features-text">
                                    <h4>1. Custom Website Development:</h4>
                                    <p>Bring your unique vision to life with our custom website development services. Our skilled
                                        team of developers crafts bespoke solutions that perfectly align with your brand, ensuring a
                                        seamless user experience across all devices.</p>
                                    <h4>2. Responsive Web Design:</h4>
                                    <p>Ensure your website looks and functions flawlessly on all devices with our responsive web
                                        design services. We prioritize a mobile-first approach, guaranteeing that your audience
                                        enjoys a consistent and engaging experience on smartphones, tablets, and desktops.</p>
                                    <h4>3. E-commerce Website Development:</h4>
                                    <p>Open new avenues for your business with our e-commerce website development expertise. We
                                        create secure, scalable, and feature-rich online stores that not only showcase your products
                                        but also provide a delightful shopping experience for your customers.</p>
                                    <h4>4. Content Management Systems (CMS):</h4>
                                    <p>Take control of your website's content effortlessly with our CMS solutions. We specialize in
                                        platforms like WordPress, Drupal, and Joomla, empowering you to manage and update your
                                        website content with ease.</p>
                                    <h4>5. Web Application Development:</h4>
                                    <p>Transform your business processes with our web application development services. Whether it's
                                        a custom CRM or an interactive dashboard, we build web applications that enhance efficiency
                                        and facilitate seamless collaboration.</p>
                                    <h4>6. UI/UX Design:</h4>
                                    <p>Make a lasting impression with our UI/UX design services. Our design team ensures that your
                                        website not only looks visually appealing but also provides an intuitive and delightful user
                                        experience, fostering engagement and brand loyalty.</p>
                                    <h4>7. Website Maintenance and Support:</h4>
                                    <p>Our commitment doesn't end with the launch. Benefit from our ongoing website maintenance and
                                        support services, ensuring your site stays up-to-date, secure, and continues to meet the
                                        evolving needs of your business.</p>
                                    <h4>8. Search Engine Optimization (SEO):</h4>
                                    <p>Increase your online visibility and drive organic traffic with our SEO services. We implement
                                        strategies to optimize your website for search engines, helping you rank higher and reach
                                        your target audience effectively.</p>
                                    <h4>9. Performance Optimization:</h4>
                                    <p>Ensure your website loads at lightning speed with our performance optimization services. We
                                        employ best practices to enhance page load times, providing users with a seamless and
                                        enjoyable browsing experience.</p>
                                    <p>At praneesh Infotech, we are committed to transforming your digital presence. Let's
                                        collaborate and build a website that not only meets but exceeds your expectations. Contact
                                        us today to discuss your website development needs and take the first step towards online
                                        success.</p>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12">
                            <aside className="services-widget">
                                <section className="widget widget_categories">
                                    <h3 className="widget-title">Our Services</h3>
                                    <ul>
                                        <li> <a href="service-android">Android Application</a></li>
                                        <li> <a href="service-ios">IOS Application</a></li>
                                        <li> <a href="service-website">Website Development</a></li>
                                        <li> <a href="service-webapp">Web App Development</a></li>
                                        <li> <a href="service-ecom">E-Commere Development</a></li>
                                        <li> <a href="service-poster">Social Media Poster Design</a></li>
                                    </ul>
                                </section>
                            </aside>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Website
