import React from 'react'
import service_image from "../images/service-android.jpg";
function Android() {
    return (
        <div>
            <div className="page-title-area">
                <div className="d-table">
                    <div className="d-table-cell">
                        <div className="container">
                            <div className="page-title-content">
                                <h2>Android Application</h2>
                                <ul>
                                    <li><a href="/">Home</a></li>
                                    <li>Android Application</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="services-details-area section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="services-details-content">
                                <div className="services-details-image">
                                    <img src={service_image} alt="service"/>
                                </div>
                                <h3>Android App Development Services</h3>
                                <p>Embark on a journey of digital innovation with our Android App Development services at praneesh Infotech. We specialize in creating dynamic, user-friendly, and feature-rich Android applications that propel businesses forward in the mobile-first era.</p>
                                <div className="features-text">
                                    <h4>1. Custom Android App Development:</h4>
                                    <p>Bring your unique ideas to life with our custom Android app development services. Our experienced team of developers tailors solutions to meet your specific business requirements, ensuring a seamless user experience and optimal performance.</p>
                                    <h4>2. Native App Development:</h4>
                                    <p>Leverage the full potential of Android devices with our native app development expertise. We create applications that harness the power of native features, delivering unmatched speed, responsiveness, and integration with the Android ecosystem.</p>
                                    <h4>3. Cross-Platform App Development:</h4>
                                    <p>Reach a broader audience with our cross-platform app development services. We use cutting-edge frameworks like Flutter and React Native to build apps that run smoothly on both Android and iOS, reducing development time and costs.</p>
                                    <h4>4. UI/UX Design for Android Apps:</h4>
                                    <p>Make a lasting impression with visually appealing and intuitive user interfaces. Our UI/UX design team ensures that your Android app not only meets industry standards but exceeds user expectations, resulting in higher user engagement and satisfaction.</p>
                                    <h4>5. Enterprise Android App Solutions:</h4>
                                    <p>Optimize your business processes with our enterprise Android app solutions. We develop applications that streamline operations, enhance productivity, and provide a secure platform for your organization's mobility needs.</p>
                                    <h4>6. E-commerce Android Apps:</h4>
                                    <p>Drive sales and enhance customer experience with our e-commerce Android app development. We create scalable and secure mobile commerce solutions that enable businesses to tap into the growing mobile market.</p>
                                    <h4>7. Social Networking Apps:</h4>
                                    <p>Connect and engage with your audience through customized social networking apps. We design and develop feature-rich applications that facilitate seamless communication, community building, and user interaction.</p>
                                    <h4>8. Maintenance and Support:</h4>
                                    <p>Our commitment extends beyond the initial development phase. Benefit from our ongoing maintenance and support services, ensuring your Android app stays up-to-date, secure, and aligned with evolving industry standards.</p>
                                    <h4>9. App Testing and Quality Assurance:</h4>
                                    <p>Ensure the reliability and performance of your Android app with our comprehensive testing and quality assurance services. We employ rigorous testing methodologies to identify and eliminate any potential issues, delivering a flawless end product.</p>
                                    <p>At praneesh Infotech, we are dedicated to transforming ideas into impactful Android applications. Elevate your business in the digital landscape with our expertise. Contact us today to discuss your Android app development needs and let's build something extraordinary together.</p>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12">
                            <aside className="services-widget">
                                <section className="widget widget_categories">
                                    <h3 className="widget-title">Our Services</h3>
                                    <ul>
                                        <li> <a href="service-android">Android Application</a></li>
                                        <li> <a href="service-ios">IOS Application</a></li>
                                        <li> <a href="service-website">Website Development</a></li>
                                        <li> <a href="service-webapp">Web App Development</a></li>
                                        <li> <a href="service-ecom">E-Commere Development</a></li>
                                        <li> <a href="service-poster">Social Media Poster Design</a></li>
                                    </ul>
                                </section>
                            </aside>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Android
