import React from 'react'
import service_image from "../images/service-ios.jpg";
function Ios() {
    return (
        <div>
            <div className="page-title-area">
                <div className="d-table">
                    <div className="d-table-cell">
                        <div className="container">
                            <div className="page-title-content">
                                <h2>IOS Application</h2>
                                <ul>
                                    <li><a href="/">Home</a></li>
                                    <li>IOS Application</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="services-details-area section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="services-details-content">
                                <div className="services-details-image">
                                    <img src={service_image} alt="service" />
                                </div>
                                <h3>iOS App Development Services</h3>
                                <p>Welcome to praneesh Infotech, where innovation meets the Apple ecosystem. Our iOS App Development services are tailored to deliver seamless, sophisticated, and user-centric applications that resonate with the high standards set by Apple.</p>
                                <div className="features-text">
                                    <h4>1. Custom iOS App Development:</h4>
                                    <p>Transform your vision into reality with our custom iOS app development services. Our expert team crafts applications that leverage the latest iOS features, ensuring a compelling user experience and optimal performance.</p>
                                    <h4>2. Native App Development:</h4>
                                    <p>Harness the full potential of Apple's hardware and software with our native iOS app development. We specialize in creating applications that integrate seamlessly with iOS devices, delivering a polished and responsive user interface.</p>
                                    <h4>3. Cross-Platform App Development:</h4>
                                    <p>Expand your reach across platforms with our cross-platform app development solutions. Using technologies like Flutter and React Native, we develop apps that run flawlessly on both iOS and Android devices, minimizing development time and costs.</p>
                                    <h4>4. iPad App Development:</h4>
                                    <p>Optimize your app for the larger screen and unique capabilities of iPads. We create iPad applications that take advantage of the device's larger canvas, providing users with an immersive and feature-rich experience.</p>
                                    <h4>5. UI/UX Design for iOS Apps:</h4>
                                    <p>Elevate your brand with visually stunning and intuitive user interfaces. Our UI/UX design team ensures that your iOS app not only adheres to Apple's design principles but also exceeds user expectations, resulting in increased user engagement.</p>
                                    <h4>6. Enterprise iOS App Solutions:</h4>
                                    <p>Empower your business with our enterprise iOS app solutions. We design and develop applications that enhance productivity, streamline workflows, and provide a secure platform for your organization's mobility needs.</p>
                                    <h4>7. E-commerce iOS Apps:</h4>
                                    <p>Enhance your online presence with our e-commerce iOS app development. We create secure and scalable mobile commerce solutions that enable businesses to tap into the vast iOS user base and drive sales.</p>
                                    <h4>8. Social Networking Apps:</h4>
                                    <p>Connect and engage with your audience through customized social networking apps for iOS. Our team develops feature-rich applications that facilitate seamless communication, community building, and user interaction.</p>
                                    <h4>9. Maintenance and Support:</h4>
                                    <p>Our commitment extends beyond the initial development phase. Benefit from our ongoing maintenance and support services, ensuring your iOS app stays current, secure, and aligned with the latest Apple updates.</p>
                                    <h4>10. App Testing and Quality Assurance:</h4>
                                    <p>Ensure the reliability and performance of your iOS app with our comprehensive testing and quality assurance services. We employ rigorous testing methodologies to identify and eliminate any potential issues, delivering a flawless end product.</p>
                                    <p>At praneesh Infotech, we are passionate about creating exceptional iOS applications that set your brand apart. Let's collaborate and bring your app ideas to life. Contact us today to discuss your iOS app development needs.</p>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12">
                            <aside className="services-widget">
                                <section className="widget widget_categories">
                                    <h3 className="widget-title">Our Services</h3>
                                    <ul>
                                        <li> <a href="service-android">Android Application</a></li>
                                        <li> <a href="service-ios">IOS Application</a></li>
                                        <li> <a href="service-website">Website Development</a></li>
                                        <li> <a href="service-webapp">Web App Development</a></li>
                                        <li> <a href="service-ecom">E-Commere Development</a></li>
                                        <li> <a href="service-poster">Social Media Poster Design</a></li>
                                    </ul>
                                </section>
                            </aside>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Ios
