import React from 'react'

function Contact() {
    return (
        <div>
            <div className="page-title-area">
                <div className="d-table">
                    <div className="d-table-cell">
                        <div className="container">
                            <div className="page-title-content">
                                <h2>Contact</h2>
                                <ul>
                                    <li><a href="/">Home</a>
                                    </li>
                                    <li>Contact</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="contact-section section-padding">
                <div className="container">
                    <div className="section-title">
                        <h2>Get In Touch</h2>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-10 offset-lg-1">
                            <div className="contact-form">
                                <p className="form-message"></p>
                                <form id="contact-form" className="contact-form form" action="" method="POST">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6">
                                            <div className="form-group">
                                                <input type="text" name="name" id="name" className="form-control" required placeholder="Your Name" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <div className="form-group">
                                                <input type="email" name="email" id="email" className="form-control" required placeholder="Your Email" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <div className="form-group">
                                                <input type="text" name="phone" id="phone" required className="form-control" placeholder="Your Mobile Number"/>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <div className="form-group">
                                                <input type="text" name="subject" id="subject" className="form-control" required placeholder="Your Subject"/>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <textarea name="message" className="form-control" id="message" cols="30" rows="6" required placeholder="Your Message"></textarea>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12">
                                            <button type="submit" className="default-btn submit-btn">Send Message <span></span></button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="contact-info-wrapper bg-grey">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="section-title">
                                <h2>Contact Info</h2>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="contact-info-content">
                                <h5>Call Us</h5>
                                <a href="tel:+919487924282">+91 9487924282</a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="contact-info-content">
                                <h5>Email Us</h5>
                                <a href="mailto:praneeshinfotech.com">praneeshinfotech.com</a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="contact-info-content">
                                <h5>Address</h5>
                                <a href="/">Kavindapadi - Erode - 638455</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Contact
