import Header from './components/header';
import Footer from './components/footer';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Service from './pages/Service';
import Android from './pages/Android';
import Ecom from './pages/Ecom';
import Ios from './pages/Ios';
import Poster from './pages/Poster';
import WebApp from './pages/WebApp';
import Website from './pages/Website';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="Contact" element={<Contact />} />
          <Route path="service" element={<Service />} />
          <Route path="service-android" element={<Android />} />
          <Route path="service-ecom" element={<Ecom />} />
          <Route path="service-ios" element={<Ios />} />
          <Route path="service-poster" element={<Poster />} />
          <Route path="service-webApp" element={<WebApp />} />
          <Route path="service-website" element={<Website />} />
          <Route path="Privacy-Policy" element={<Privacy />} />
          <Route path="Terms-Condition" element={<Terms />}/>
        </Routes>
        <Footer />
      </BrowserRouter>      
    </div>
  );
}
export default App;